import store from "../store/store";

/**
 * @class BearerTokenManager
 * @classdesc
 * Get a bearer token object from Action api Laravel Passport OAuth server.
 **/
 export default class BearerTokenManager {
    constructor() {
        this.token = null;
    }

    /**
     * @function getNewTokenFromApi
     * @description Get a bearer token from the server.
     * @returns {Promise<Token>}
     **/
     getNewTokenFromApi() {
        const oauthClient = this.createOAuthClient();

        console.clear();

        return oauthClient.credentials
        .getToken()
        .then((token) => {
            this.storeNewToken(token);
            return token;
        })
        .catch((err) => {
            console.log(err);
        });
    }

    /**
     * @function createOAuthClient
     * @description Create an OAuth client.
     * @returns {ClientOAuth2}
     **/
     createOAuthClient() {
        const ClientOAuth2 = require("client-oauth2");
        return new ClientOAuth2({
            clientId: process.env.VUE_APP_CLIENT_ID,
            clientSecret: process.env.VUE_APP_CLIENT_SECRET,
            accessTokenUri: `${process.env.VUE_APP_CLIENT_DOMAIN}/oauth/token`,
            scopes: ["*"],
        });
    }

    /**
     * @function checkBearerTokenValidity
     * @description Check if the bearer token is valid.
     * if it is not valid, get a new one.
     * @returns void
     **/
     static checkBearerTokenValidity() {
        console.log("hello");
        // let bearerToken = store.getters.getBearerToken();
        // console.log(bearerToken);
        // if (bearerToken.isValid()) {
        //   return;
        // }

        // bearerToken.refresh().then((token) => this.storeNewToken(token));
    }

    storeNewToken(token) {
        // console.log("commit token:", token);
        store.commit("SET_BEARER_TOKEN", token);
    }
}
